<template>
  <div class="wrap">
    <div class="header">
      <div class="title"><img
			src="../assets/img/return_index.png"
			class="return1"
			alt=""
			@click="$router.back()"
        	/>{{$t('main.detailappre')}}</div>
      <div class="right-icon">
        <img
          src="../assets/img/logo-icon.png"
          alt=""
        />
      </div>
    </div>
    <!-- 放大图 -->
    <!-- <div id="bigImage" v-show="bigitem === true" @click="magnify">
          <img src="../assets/img/图层 2 拷贝.png" alt="">
        </div> -->
    <!-- 每一块区域 -->
    <div class="container">
      <div
        class="box"
        v-for="(item, index) in detail_info"
        :key="index"
      >
        <div class="box-header">
          <img
            class="smallImg"
            src="../assets/img/ghosting.png"
            alt=""
          />
          <div class="box-title">{{$t('main.apprePic')}} {{ index + 1 }}</div>
          <img
            src="../assets/img/close-icon.png"
            class="concan"
            @click="concan(index)"
            alt=""
          />
        </div>

        <textarea
          class="add-input"
          type="text"
          ref="email"
          :class="item.content ? 'active' : ''"
          v-model="item.content"
          :placeholder="$t('main.pedescantimap')"
        />
        <div
          class="add-img"
          @click="uploadImg(index)"
        > 
          <div class="smalldiv" v-if="!item.pic.length"
            >
          <img
          v-show="!load_list[index]"
            class="smallImg"
            src="../assets/img/photo.png"
            alt=""
          />
        </div>
          <img
            class="bigImg"
            v-if="item.pic.length"
            :src="item.pic"
            alt=""
          />
          <div
            class="loading_i"
            v-show="load_list[index]"
          >
            <van-loading
              type="spinner"
              color="#fff"
              size="0.24rem"
              vertical
              >{{$t('main.uploading')}}</van-loading
            >
          </div>
        </div>
        <div class="explanation">{{$t('main.updpot')}}</div>
      </div>
      <div
        class="add-btn"
        @click="addBtn"
        v-if="detail_info.length <= 9"
      >
      <span class="add-icon">+</span><span>{{$t('main.add')}}</span>
      </div>
    </div>
    <div
      class="footerBtn"
      @click="handle"
    >
      {{$t('main.submit')}}
    </div>
    <img style="display:none" id="shuiyin" src="../assets/img/logo2.png" alt="">
    <privacy ref="privacy"></privacy>
  </div>
</template>

<script scoped>
import privacy from "../components/privacy.vue"
export default {
  components: {
    privacy
  },
  data() {
    return {
      detail_info: [
        {
          pic: "",
          hpic: "",
          content: "",
        },
        {
          pic: "",
          hpic: "",
          content: "",
        },
        {
          pic: "",
          hpic: "",
          content: "",
        },
      ],
      before_detail_info: [],
      load_list: [false, false, false],
      detail_status:""
    };
  },
  async mounted() {
    let token = localStorage.getItem('user_token')
    if(this.$route.query.code && !token){
      let querys= ''
            if(this.$route.query.phone){
                let phone =this.getQueryString('phone')
                let nickName = this.getQueryString('phone_code')
                querys = `&phone=${phone}&phone_code=${decodeURIComponent(nickName)}`
            }
        await this.axios.get('/auth/nt?code='+this.$route.query.code+querys).then((res)=>{
          if(res.data.code == -1 && res.data.msg == '请填写手机号'){
                    
                    this.$refs.privacy.openPopup()
                    return
                }
          window.localStorage.setItem('user_token',res.data.data.token)
        }).catch(() => {})
    }
   
    let url = location.protocol + "//" + location.host;
    let param = this.$route.fullPath;
    const id = this.$route.params.id;
    this.axios.post(`/goods/getEditInfo`,{id}).then((res) => {
      if (res.data.data.detail_info) {
        this.detail_info = res.data.data.detail_info;
        this.before_detail_info = JSON.parse(
          JSON.stringify(res.data.data.detail_info)
        );
        this.load_list = [];
        for (let i = 0; i < this.detail_info.length; i++) {
          this.load_list.push(false);
        }
      }
      if(res.data.data.detail_status == 2 && res.data.data.detail_fail_reason){
        weui.alert(res.data.data.detail_fail_reason,{buttons:[{label:this.$t('main.confIrm')}]})
        return
      }
    });
    this.axios
      .post("/auth/wechatConfig", {
        url: url + this.$store.state.jssdkUrl,
        jsApiList: ["chooseImage", "getLocalImgData"],
      })
      .then((res) => {
        let json = JSON.parse(res.data.data.data);
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: json.appId, // 必填，公众号的唯一标识
          timestamp: json.timestamp, // 必填，生成签名的时间戳
          nonceStr: json.nonceStr, // 必填，生成签名的随机串
          signature: json.signature, // 必填，签名
          jsApiList: json.jsApiList, // 必填，需要使用的 JS 接口列表
        });
      });
  },
  methods: {
    getQueryString(name) {
			var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
			var r = window.location.search.substr(1).match(reg);
			if (r != null) return decodeURIComponent(r[2]);
			return null;
		},
    uploadImg(index) {
      // 从手机相册中选图
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["original"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album"], // 可以指定来源是相册还是相机，默认二者都有
        success: (res) => {
          this.load_list.splice(index, 1,true)
          wx.getLocalImgData({
            localId: res.localIds[0], // 图片的localID
            success: async(res) => {
              let localData = res.localData;
              if (localData.indexOf(",") == -1) {
                localData = "data:image/jpg;base64," + localData;
              }
              let shuiyin = document.getElementById('shuiyin')
              var origImg = new Image();
              var img = new Image();
              img.onload = () => {
                try {
                  const canvas = document.createElement("canvas");
                  const ctx = canvas.getContext("2d");
                  canvas.width = parseInt(img.width / 2);
                  canvas.height = parseInt(img.height / 2);
                  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                  let shuiyin_width = canvas.width / 8
                  let shuiyin_height = shuiyin_width / 6.7
                  ctx.drawImage(shuiyin, (canvas.width - shuiyin_width), 20, shuiyin_width, shuiyin_height);
                  const piccc = canvas.toDataURL("image/jpeg",0.5);
                  const images1 = this.dataURLtoBlob(piccc);
                  console.log(piccc)
                  var param1 = new FormData();
                  console.log(images1)
                  param1.append("file", images1, "test.jpg", {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  });
                  
                  setTimeout(()=>{
                    for (var value of param1.values()) {
                        console.log(value);
                    }
                    this.axios.post("/common/uploadImg", param1).then((res) => {
                      this.detail_info[index].pic = res.data.data.url;
                      this.load_list.splice(index, 1,false);
                      console.log(this.load_list)
                    });
                  },500)
                  
                } catch (error) {
                  console.log(error)
                }
                
              };
              origImg.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = origImg.width;
                canvas.height = origImg.height;
                ctx.drawImage(origImg, 0, 0, canvas.width, canvas.height);
                let shuiyin_width = canvas.width / 8
                let shuiyin_height = shuiyin_width / 6.7
                ctx.drawImage(shuiyin, (canvas.width - shuiyin_width), 20, shuiyin_width, shuiyin_height);
                const origpic = canvas.toDataURL("image/jpeg",0.9);
                console.log(origpic)
                let images = this.dataURLtoBlob(origpic);
                let param = new FormData();
                param.append("file", images, "test.jpg", {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                });
                this.axios.post("/common/uploadImg", param).then((res) => {
                  this.detail_info[index].hpic = res.data.data.url;
                  setTimeout(()=>{
                    img.src = localData;
                  },500)
                });

              }
              origImg.src = localData;
              
              
              
              
            },
          });
        },
      });
    },
    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(",");
      var mime = "image/jpeg";

      var bstr = arr[1] ? atob(arr[1]) : atob(arr[0]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime,
      });
    },
    showText(index) {
      this.$nextTick(() => {
        console.log(this.$refs.email);
        this.$refs.email[index].focus();
      });
    },
    addBtn() {
      this.detail_info.push({
        pic: "",
        hpic: "",
        content: "",
      });
      this.load_list.push(false);
    },
    concan(index) {
      this.detail_info.splice(index, 1);
      this.load_list.splice(index, 1);
    },
    handle() {
      for(let i= 0; i < this.load_list.length; i++){
        if(this.load_list[i]){
          weui.alert(this.$t('main.lpssu'),{buttons:[{label:this.$t('main.confIrm')}]})
          return 
        }
      }
      for (let i = 0; i < this.detail_info.length; i++) {
        if (this.detail_info[i].hpic == "" && this.detail_info[i].content) {
          weui.alert(this.$t('main.dcbsp'),{buttons:[{label:this.$t('main.confIrm')}]});
          return;
        }
      }

      const id = this.$route.params.id;
      const detail_info = this.detail_info.filter((item) => {
        return item.hpic !== "";
      });
      const before_detail_info =  this.before_detail_info.filter((item) => {
        return item.hpic !== "";
      });
      if (detail_info.length < 1 && before_detail_info.length < 1) {
        return weui.alert(this.$t('main.puaci'),{buttons:[{label:this.$t('main.confIrm')}]});
      }
      if(detail_info.length < 1 && before_detail_info.length >= 1){
        weui.alert(this.$t('main.aysutosub'),{
						buttons: [{
							label: this.$t('main.cancel'),
							type: 'primary',
							onClick: () => {
								
							}
						}, {
							label: this.$t('main.determine'),
							onClick: () => {
								this.delete_de(id)
							}
						}]})
          return
      }
      let flag = 0;
      if (detail_info.length !== this.before_detail_info.length) {
        flag = 1;
      } else {
        for (let i = 0; i < this.before_detail_info.length; i++) {
          if (
            this.before_detail_info[i].pic != detail_info[i].pic ||
            this.before_detail_info[i].content != detail_info[i].content
          ) {
            flag = 1;
            break;
          }
        }
      }

      if (!flag) {
        return weui.alert(this.$t('main.inm'),{buttons:[{label:this.$t('main.confIrm')}]});
      }
      weui.alert(this.$t('main.aysutosub'),{
						buttons: [{
							label: this.$t('main.cancel'),
							type: 'primary',
							onClick: () => {
								
							}
						}, {
							label: this.$t('main.determine'),
							onClick: () => {
								this.editData(id,detail_info)
							}
						}]})
      
    },
    editData(id,detail_info){

      this.axios
        .post("/goods/addDetailInfo", {
          id,
          detail_info,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$router.push(`/adtiViolation/${id}`);
            this.axios.post('/goods/transGoodsDetailInfo',{id})
          }else{
            setTimeout(()=>{
              weui.alert(res.data.msg,{buttons:[{label:this.$t('main.confIrm')}]})
            },500)
          }
        });
    },
    delete_de(id){
      this.axios
        .post("/goods/deleteDetailInfo", {
          id
        })
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$router.go(-1);
          } else {
            
          }
        });
    }
  },
};
</script>

<style scoped>
.header {
  display: flex;
    justify-content: space-between;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    margin-top: 0.01rem;
    padding: 0.05rem 0.15rem;
    align-items: center;
    background: #fff;
}

.title {
  font-size: 0.18rem;
  color: rgb(38,111,43);
}


.right-icon {
  width: 1rem;
    height: 0.39rem;
}

.right-icon img {
  object-fit: contain;
  display: block;
  width: 100%;
}

/* 放大图 */
#bigImage {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#bigImage img {
  width: 100%;
}

/* 每一块区域 */
.container {
  background-color: rgb(235, 230, 224);
  padding: 0.05rem 0 10px;
  border-radius: 0.05rem;
  min-height: 100vh;
}

.box {
  border: 1px solid #edebe7;
  width: 84%;
  margin: 0.1rem 0 0 0.15rem;
  border-radius: 0.1rem;
  background-color: #fff;
  padding: 0.15rem;
  box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem #ccc;
}
.box-header {
  position: relative;
}
.box-header .box-title {
  font-weight: 600;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
}
.box-header .concan {
  position: absolute;
  right: 0.05rem;
  top: 0;
  width: 24px;
  height: 24px;
}

.box .smallImg {
  width: 0.16rem;
  margin-right: 0.1rem;
  margin-top: 0.03rem;
  float: left;
}

.box .bigImg {
  width: 84%;
}

.box .footer {
  display: flex;
  justify-content: center;
}

.box .text {
  color: #cdcdcd;
}

.box .text img {
  width: 0.1rem;
}

.box .footer .footerImg {
  width: 1.4rem;
}

.add-img {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 0.1rem;
  margin: 0.05rem 0;
  overflow: hidden;
  position: relative;
}

.add-input {
  width: 100%;
  border: none;
  background: url("../assets/img/edit.png") no-repeat;
  background-size: auto 0.12rem;
  background-position: left 0.05rem;
}

.add-input::placeholder {
  padding-left: 0.2rem;
}

.add-input.active {
  background: none;
}

.add-img .smallImg {
  width: 0.2rem;
}

.add-img .smalldiv{
  width: 100%;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-img .bigImg {
  width: 100%;
  height: 3.17rem;
  object-fit: cover;
}

.explanation {
  font-size: 0.1rem;
  color: #cdcdcd;
  margin-top: 0.05rem;
  display: flex;
  flex-direction: row-reverse;
}
.add-btn {
  background-color: white;
  color: #2d71d7;
  width: 92%;
  height: 0.4rem;
  margin: 0.15rem 0 0.4rem 0.15rem;
  border-radius: 0.05rem;
  display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 0;
		line-height: normal;
}
.add-btn .add-icon{
		font-size: 0.25rem;
		margin-right: 0.05rem;
	}
.footerBtn {
  background-color: #2d70d8;
  color: white;
  width: 94%;
  height: 0.4rem;
  position: fixed;
  bottom: 0.05rem;
  left: 3%;
  border-radius: 0.05rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.loading_i {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #1989fa;
  font-size: 0.12rem;
  background: rgba(0, 0, 0, 0.6);
}
</style>
